import './MainSite.scss';
import {Interface} from '../Utility/Interface';
import {TabBar} from '../Utility/Components/TabBar';
import {Utility} from '../Utility/Utility';
import {IGenericPage} from '../Utility/Components/IGenericPage';
import {BiographyPage} from "./Biography/BiographyPage";
import {ContactPage} from "./Contact Me/ContactPage";
import {ProjectsPage} from "./Projects/ProjectsPage";
import {PortfolioPage} from "./Portfolio/PortfolioPage";
import {ResumePage} from "./Resume/ResumePage";
import {WebHistory} from "../Utility/WebHistory";

/**
 * Handles initializing the entire interface for the main site.
 */
export class MainSite {
    mainInterface: HTMLElement;
    subdomainBar: TabBar;
    topBar : TabBar;
    pageHolder: HTMLElement;
    currentPage : IGenericPage = null;
    biographyPage : BiographyPage;
    contactPage : ContactPage;
    projectsPage : ProjectsPage;
    portfolioPage : PortfolioPage;
    resumePage : ResumePage;
    constructor() {
        this.mainInterface = Interface.Create({
            type: 'div', className: 'MainSite', elements: [
                (this.subdomainBar = new TabBar('TopSubdomainBar')).getInterface(),
                (this.topBar = new TabBar('TopTabBar')).getInterface(),
                this.pageHolder = Interface.Create({type: 'div', className: 'PageHolder'})
            ]
        });

        //Create pages
        this.biographyPage = new BiographyPage();
        this.contactPage = new ContactPage();
        this.projectsPage = new ProjectsPage();
        this.portfolioPage = new PortfolioPage();
        this.resumePage = new ResumePage();

        //Make top bars
        this.subdomainBar.addItem({name: 'MatthewFrench.io'});
        // Commented other subdomains since I'm moving them to the front of the projects page
        //this.subdomainBar.addItem({name: 'Art Platformer Online', onClick: this.artPlatformerClicked});
        //this.subdomainBar.addItem({name: 'Forums', onClick: this.forumClicked});
        //Add the main site tabs
        this.topBar.addItem({value: this.projectsPage, name: 'Projects', onClick: ()=>{this.setCurrentPage(this.projectsPage);}});
        this.topBar.addItem({value: this.resumePage, name: 'Resume', onClick: ()=>{this.setCurrentPage(this.resumePage);}});
        this.topBar.addItem({value: this.portfolioPage, name: 'Portfolio', onClick: ()=>{this.setCurrentPage(this.portfolioPage);}});
        this.topBar.addItem({value: this.biographyPage, name: 'Biography', onClick: ()=>{this.setCurrentPage(this.biographyPage);}});
        this.topBar.addItem({value: this.contactPage, name: 'Contact Me', onClick: ()=>{this.setCurrentPage(this.contactPage);}});

        //Select the current pages
        this.subdomainBar.setSelectedItemByIndex(0);

        //Register web history triggers
        WebHistory.RegisterCallbackForAllStateChanges(this.loadPage);

        //Activate web history
        WebHistory.Activate();
        //Load page if there's a page in the URL
        if (WebHistory.HasStartPage()) {
            WebHistory.LoadStartPageFromURL();
        } else {
            this.topBar.setSelectedItemByValue(this.projectsPage);
        }
    }

    loadPage = (pageArray : string[], data : {}) => {
        //Load page
        if (pageArray.length === 1) {
            let page = pageArray[0];
            switch (page) {
                case 'Projects': {
                    this.topBar.setSelectedItemByValue(this.projectsPage);
                } break;
                case 'Resume': {
                    this.topBar.setSelectedItemByValue(this.resumePage);
                } break;
                case 'Portfolio': {
                    this.topBar.setSelectedItemByValue(this.portfolioPage);
                } break;
                case 'Biography': {
                    this.topBar.setSelectedItemByValue(this.biographyPage);
                } break;
                case 'Contact': {
                    this.topBar.setSelectedItemByValue(this.contactPage);
                } break;
                default: {
                    this.topBar.setSelectedItemByValue(this.projectsPage);
                }
            }
        } else {
            this.topBar.setSelectedItemByValue(this.projectsPage);
        }
    };

    /**
     * Removes the currently viewed page.
     */
    removeCurrentPage = () => {
        if (this.currentPage === null) {
            return;
        }
        this.currentPage.hidePage();
        this.currentPage.getInterface().remove();
    };

    /**
     * Sets the current page.
     * @param {IGenericPage} page
     */
    setCurrentPage = (page : IGenericPage) => {
        this.removeCurrentPage();
        this.currentPage = page;
        this.pageHolder.appendChild(this.currentPage.getInterface());
        this.currentPage.showPage();
        this.pageHolder.scrollTo(0,0);
    };

    /**
     * Opens a link to the art platformer.
     */
    artPlatformerClicked = () => {
        Utility.OpenWebpage('https://art.matthewfrench.io/');
        this.subdomainBar.setSelectedItemByIndex(0);
    };

    /**
     * Opens a link to the form.
     */
    forumClicked = () => {
        Utility.OpenWebpage('https://forum.matthewfrench.io/');
        this.subdomainBar.setSelectedItemByIndex(0);
    };

    /**
     * Gets the interface of the documents page.
     * @returns {*}
     */
    getInterface() {
        return this.mainInterface;
    }
}
