import './BiographyPage.scss';
import {IGenericPage} from "../../Utility/Components/IGenericPage";
import {Interface} from "../../Utility/Interface";
import {WebHistory} from "../../Utility/WebHistory";
import {Utility} from "../../Utility/Utility";

export class BiographyPage implements  IGenericPage {
    mainInterface: HTMLElement;
    selfPicture: HTMLDivElement;
    wifePicture: HTMLDivElement;
    catPicture: HTMLDivElement;
    constructor() {
        this.mainInterface = Interface.Create({type: 'div', className: 'BiographyPage', elements: [
                {type: 'div', className: 'SelfPictureDiv', title: 'Picture of Me', elements: [
                    this.selfPicture = Interface.Create({type: 'div', className: 'SelfPicture'}),
                    {type: 'div', className: 'SelfPictureText', text: 'Me'}
                ]},
                {type: 'span', className: 'BiographyText', text: 'My name is Matthew French. I am ' + Utility.GetYearsBetweenDates(new Date(), new Date('April 9, 1993 00:00:00')) + ' years old and have been developing software since I was 8. At 8 I was only using simple WYSIWYG editors such as Al\'s GameMaker. I started officially coding at the age of 13 when code just clicked and made sense. Since then I have made many hobby products including games and editors written for iOS, Java, C#, C++, D, PHP, Typescript, Javascript and many more languages and platforms. '},
                {type: 'div', className: 'WifePictureDiv', title: 'Picture of Wife', elements: [
                        this.wifePicture = Interface.Create({type: 'div', className: 'WifePicture'}),
                        {type: 'div', className: 'WifePictureText', text: 'Wife'}
                    ]},
                {type: 'p'},
                {type: 'span', className: 'BiographyText', text: 'Besides creating software and games, I enjoy running.'},
                {type: 'p'},
                {type: 'div', className: 'CatPictureDiv', title: 'Picture of Cats', elements: [
                        this.catPicture = Interface.Create({type: 'div', className: 'CatPicture'}),
                        {type: 'div', className: 'CatPictureText', text: 'Cats'}
                    ]},
         ]});
        //Pre-load images
        let image = new Image();
        image.src = require('../../../static/assets/BiographyMatthewFrench.png');
        this.selfPicture.appendChild(image);
        image = new Image();
        image.src = require('../../../static/assets/BiographyTara.png');
        this.wifePicture.appendChild(image);
        image = new Image();
        image.src = require('../../../static/assets/BiographyCats.png');
        this.catPicture.appendChild(image);
    }
    showPage() {
        WebHistory.PushStateChange(['Biography']);
    }
    hidePage() {

    }
    getInterface() {
        return this.mainInterface;
    }
}