import './ContactPage.scss';
import {IGenericPage} from "../../Utility/Components/IGenericPage";
import {Interface} from "../../Utility/Interface";
import {WebHistory} from "../../Utility/WebHistory";

export class ContactPage implements  IGenericPage {
    mainInterface: HTMLElement;
    constructor() {
        this.mainInterface = Interface.Create({type: 'div', className: 'ContactPage', elements: [
            {type: 'div', className: 'ContactDiv', elements: [
                {type: 'div', className: 'ContactText', text: 'Contact Matthew French at'},
                {type: 'a', className: 'ContactLink', href: 'mailto:matthew.roy.french@gmail.com', text: 'matthew.roy.french@gmail.com'}
            ]}
        ]});
    }
    showPage() {
        WebHistory.PushStateChange(['Contact']);
    }
    hidePage() {

    }
    getInterface() {
        return this.mainInterface;
    }
}