import {Interface} from "../../Utility/Interface";

export class PortfolioProject {
    mainInterface: HTMLElement;
    contentDiv: HTMLDivElement;
    constructor(title : string, dateString : string, tools: string, content: any) {
        this.mainInterface = Interface.Create({type: 'div', className: 'PortfolioProject', elements: [
                {type: 'div', className: 'Header', elements: [
                    {type: 'div', className: 'Title', text: title},
                    {type: 'div', className: 'Date', text: dateString}
                ]},
                this.contentDiv = Interface.Create({type: 'div', className: 'Content'}),
                {type: 'hr', className: 'FooterHR'},
                {type: 'div', className: 'Footer', elements: [
                    {type: 'div', className: 'Tools', text: tools}
                ]}
            ]});
        if (typeof content === 'string') {
            this.contentDiv.innerText = content;
        } else {
            if (content instanceof Element) {
                this.contentDiv.appendChild(content);
            } else {
                this.contentDiv.appendChild(Interface.Create(content));
            }
        }
    }
    getInterface() {
        return this.mainInterface;
    }
}