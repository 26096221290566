import {Interface} from "../../Utility/Interface";
import {PortfolioProject} from "./PortfolioProject";

export class PortfolioSection {
    mainInterface: HTMLElement;
    projectDiv : HTMLDivElement;
    projects: PortfolioProject[];
    constructor(title : string, dateRange : string) {
        this.mainInterface = Interface.Create({type: 'div', className: 'PortfolioSection', elements: [
            {type: 'div', className: 'PortfolioSectionHeader', elements: [
                {type: 'div', className: 'Title', text: title},
                {type: 'div', className: 'DateRange', text: dateRange},
            ]},
            this.projectDiv = Interface.Create({type: 'div', className: 'Projects'})
        ]});
        this.projects = [];
    }
    addProject(project : PortfolioProject) {
        this.projects.push(project);
        this.projectDiv.appendChild(project.getInterface());
    }
    getInterface() {
        return this.mainInterface;
    }
}