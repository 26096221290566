import './ResumePage.scss';
import {IGenericPage} from "../../Utility/Components/IGenericPage";
import {Interface} from "../../Utility/Interface";
import {WebHistory} from "../../Utility/WebHistory";
import {Utility} from "../../Utility/Utility";

export class ResumePage implements  IGenericPage {
    mainInterface: HTMLElement;
    constructor() {
        this.mainInterface = Interface.Create({type: 'div', className: 'ResumePage', elements: [
            {type: 'div', className: 'Name', text: 'Matthew French'},
            {type: 'div', className: 'Title', text: 'Enterprise Senior Software Engineer and Technical Lead'},
            {type: 'a', className: 'Contact', href: 'mailto:matthew.roy.french@gmail.com', text: 'matthew.roy.french@gmail.com'},
            {type: 'div', className: 'MainHeader', text: 'Professional Qualifications'},
            {type: 'hr', className: 'MainHR'},
            {type: 'ul', className: 'QualificationsList', elements: [
                {type: 'li', className: 'ListElement', text: Utility.GetYearsBetweenDates(new Date(), new Date('January 1, 2010 00:00:00')) + '+ years of professional software engineering experience'},
                {type: 'li', className: 'ListElement', text: 'Experience in many programming languages, backend, frontend, low-level, and high-level'},
                    {type: 'li', className: 'ListElement', text: 'NoSQL, SQL, and big data database management and usage experience'},
                    {type: 'li', className: 'ListElement', text: 'Developed products for iOS, Android, web, desktop, and server-side'},
                    {type: 'li', className: 'ListElement', text: 'Technically led and managed multiple teams to product success, mentored and trained members, held courses and knowledge shares'},
                    {type: 'li', className: 'ListElement', text: 'Conducted hundreds of technical, product, and SWE interviews. Trained new interviewers.'},
                    {type: 'li', className: 'ListElement', text: 'Intimately familiar with enterprise software engineering best practices, and implementing best practices and team processes'},
                    {type: 'li', className: 'ListElement', text: 'Led many products to substantial revenue growth'},
            ]},
            {type: 'div', className: 'MainHeader', text: 'Work Experience'},
            {type: 'hr', className: 'MainHR'},

                {type: 'div', className: 'PlaceLocationDate', elements: [
                        {type: 'a', className: 'Place', text: 'Indeed', href: '#Portfolio'},
                        {type: 'div', className: 'Location', text: ' - Remote'},
                        {type: 'div', className: 'Date', text: '2022 to now'}
                    ]},
                {type: 'div', className: 'Position', text: 'Senior Software Engineer / Technical Lead'},
                {type: 'ul', className: 'WorkTasks', elements: [
                        {type: 'li', className: 'ListElement', text: 'Led a team of 10 engineers for successfully rolling out a major platform.'},
                        {type: 'li', className: 'ListElement', text: 'Conducted SWE interviews.'},
                        {type: 'li', className: 'ListElement', text: 'Regularly mentored engineers internally and cross-team.'},
                        {type: 'li', className: 'ListElement', text: 'Improved products with newer frontend technologies and build/automation pipelines.'},
                        {type: 'li', className: 'ListElement', text: 'Held pair programming, design reviews, and best practice knowledge shares.'},
                        {type: 'li', className: 'ListElement', text: 'Realized efforts resulting in a 40% increase in US revenue and 100% increase in non-US revenue in team products.'},
                        {type: 'li', className: 'ListElement', text: 'Worked closely with engineers to revamp the new user flow and the main homepage experience for new users to maximize value.'},
                    ]},

                {type: 'div', className: 'PlaceLocationDate', elements: [
                        {type: 'a', className: 'Place', text: 'Indeed', href: '#Portfolio'},
                        {type: 'div', className: 'Location', text: ' - Remote'},
                        {type: 'div', className: 'Date', text: '2021 to 2022'}
                    ]},
                {type: 'div', className: 'Position', text: 'Software Engineer II / Tech Lead'},
                {type: 'ul', className: 'WorkTasks', elements: [
                        {type: 'li', className: 'ListElement', text: 'Conducted 100+ SWE interviews and trained new interviewers.'},
                        {type: 'li', className: 'ListElement', text: 'Led an offshore team and trained new members.'},
                        {type: 'li', className: 'ListElement', text: 'Led, planned and delegated work for multiple team members.'},
                        {type: 'li', className: 'ListElement', text: 'Discovered and resolved multiple major issues.'},
                        {type: 'li', className: 'ListElement', text: 'Migrated major product internals to GraphQL.'},
                        {type: 'li', className: 'ListElement', text: 'Implemented revenue tracking within internal systems.'},
                        {type: 'li', className: 'ListElement', text: 'Mentored many engineers both in-team and cross-team.'},
                        {type: 'li', className: 'ListElement', text: 'Implemented horizontal pod autoscaling in many products.'},
                        {type: 'li', className: 'ListElement', text: 'Owned creation and deployment of high-load user behavior logging and analysis.'},
                        {type: 'li', className: 'ListElement', text: 'Hosted and led internal learning courses and sessions.'},
                        {type: 'li', className: 'ListElement', text: 'Set up SSR for frontend product, implemented semantic versioning, upgraded infrastructure to use Terraform infrastructure as code.'},
                    ]},

            {type: 'div', className: 'PlaceLocationDate', elements: [
                {type: 'a', className: 'Place', text: 'Indeed', href: '#Portfolio'},
                {type: 'div', className: 'Location', text: ' - Remote'},
                {type: 'div', className: 'Date', text: '2020 to 2021'}
            ]},
            {type: 'div', className: 'Position', text: 'Software Engineer'},
            {type: 'ul', className: 'WorkTasks', elements: [
                {type: 'li', className: 'ListElement', text: 'Led and trained an offshore team to work on new products.'},
                    {type: 'li', className: 'ListElement', text: 'Mentored other members and trained new interviewers.'},
                    {type: 'li', className: 'ListElement', text: 'Conducted business, technical, and SWE interviews.'},
                    {type: 'li', className: 'ListElement', text: 'Handled designated first responder and manager schedules and duties.'},
                    {type: 'li', className: 'ListElement', text: 'Substantial backend development for internal SDKs and libraries as well as frontend React/SCSS work.'},
                    {type: 'li', className: 'ListElement', text: 'Supported cross-team collaboration and initiatives.'},
                    {type: 'li', className: 'ListElement', text: 'Set up repositories, CI/CD processes, deploy/publish pipelines, and managed databases.'},
                    {type: 'li', className: 'ListElement', text: 'Migrated legacy frontend projects to modern technology stacks.'},
                    {type: 'li', className: 'ListElement', text: 'Implemented GraphQL API usage in various products.'},
                    {type: 'li', className: 'ListElement', text: 'Conducted SWE interviews.'},
                    {type: 'li', className: 'ListElement', text: 'Created and held design reviews for products and ideas.'},
            ]},

            {type: 'div', className: 'PlaceLocationDate', elements: [
                {type: 'a', className: 'Place', text: 'Indeed', href: '#Portfolio'},
                {type: 'div', className: 'Location', text: ' - Austin, Texas'},
                {type: 'div', className: 'Date', text: '2019 to 2020'}
            ]},
            {type: 'div', className: 'Position', text: 'Product Developer'},
            {type: 'ul', className: 'WorkTasks', elements: [
                {type: 'li', className: 'ListElement', text: 'Worked with PMs, designed, built, and stood up a completely new website platform targeted at the German market.'},
                    {type: 'li', className: 'ListElement', text: 'Conducted business and technical interviews.'},
                    {type: 'li', className: 'ListElement', text: 'Worked closely with the marketing team to upgrade the email sender to send out 50 million emails per hour.'},
                    {type: 'li', className: 'ListElement', text: 'Handled release management and DFR duties, trained new members.'},
                    {type: 'li', className: 'ListElement', text: 'Created an automatic daily sitemap creator and publisher to improve SEO.'},
                    {type: 'li', className: 'ListElement', text: 'Created a bulk quota submission tool for the sales and management teams to submit quotas.'},
                    {type: 'li', className: 'ListElement', text: 'Closely collaborated with the Japan and APAC teams.'},
            ]},

            {type: 'div', className: 'PlaceLocationDate', elements: [
                {type: 'a', className: 'Place', text: 'Indeed', href: '#Portfolio'},
                {type: 'div', className: 'Location', text: ' - Austin, Texas'},
                {type: 'div', className: 'Date', text: '2018 to 2019'}
            ]},
            {type: 'div', className: 'Position', text: 'Research Software Engineer / Technical Business Analyst'},
            {type: 'ul', className: 'WorkTasks', elements: [
                {type: 'li', className: 'ListElement', text: 'Created a product to process millions of resumes to calculate the average employment length at companies in various locations.'},
                    {type: 'li', className: 'ListElement', text: 'Worked closely with the job aggregation team and vendors.'},
                    {type: 'li', className: 'ListElement', text: 'Conducted 80+ interviews focused on measuring business, product, and analysis acumen.'},
                    {type: 'li', className: 'ListElement', text: 'Maintained job aggregation systems and managed databases.'},
                    {type: 'li', className: 'ListElement', text: 'Designed and created an automatic system for detecting new jobs on scraped webpages and queueing them for scraping, providing about $3 million of value per year.'},
                    {type: 'li', className: 'ListElement', text: 'Built out a new responsive homepage for one of the major products.'},
                    {type: 'li', className: 'ListElement', text: 'On-call responder and designated first responder.'},
            ]},

            {type: 'div', className: 'PlaceLocationDate', elements: [
                {type: 'a', className: 'Place', text: 'Network Computer Solutions', href: '#Portfolio'},
                {type: 'div', className: 'Location', text: ' - St. George, Kansas'},
                {type: 'div', className: 'Date', text: '2017 to 2018'}
            ]},
            {type: 'div', className: 'Position', text: 'Software Engineer'},
            {type: 'ul', className: 'WorkTasks', elements: [
                    {type: 'li', className: 'ListElement', text: 'Worked with a team to produce stable, secure and intuitively designed software for clients.'},
                {type: 'li', className: 'ListElement', text: 'Designed full products being used in hospitals, clinics and care centers.'},
                {type: 'li', className: 'ListElement', text: 'Updated toolchains, code bases and standards to the most up to date and secure quality.'},
                {type: 'li', className: 'ListElement', text: 'Built web clients, desktop applications, payment portals, database structures and server APIs.'},
                    {type: 'li', className: 'ListElement', text: 'Greatly improved developer operations, set up Ci/CD and push on green deploy.'}
            ]},

            {type: 'div', className: 'PlaceLocationDate', elements: [
                {type: 'a', className: 'Place', text: 'Kansas State University', href: '#Portfolio'},
                {type: 'div', className: 'Location', text: ' - Manhattan, Kansas'},
                {type: 'div', className: 'Date', text: '2016 to 2017'}
            ]},
            {type: 'div', className: 'Position', text: 'Cyber Security Engineer'},
            {type: 'ul', className: 'WorkTasks', elements: [
                {type: 'li', className: 'ListElement', text: 'Made interactive real-time web interfaces and database backends with all the connections in-between to study big data research.'},
                {type: 'li', className: 'ListElement', text: 'Designed and manipulated big data relational databases.'},
                {type: 'li', className: 'ListElement', text: 'Wrote pattern analysis software for detecting university network threats for both offline big data stores and high-throughput live data.'}
            ]},

            {type: 'div', className: 'PlaceLocationDate', elements: [
                {type: 'a', className: 'Place', text: 'Kansas State University', href: '#Portfolio'},
                {type: 'div', className: 'Location', text: ' - Manhattan, Kansas'},
                {type: 'div', className: 'Date', text: '2014 to 2016'}
            ]},
            {type: 'div', className: 'Position', text: 'Software Developer / Medical Applications'},
            {type: 'div', className: 'PositionDescription', text: 'Worked with product managers to produce research based medical software.'},
            {type: 'ul', className: 'WorkTasks', elements: [
                {type: 'li', className: 'ListElement', text: 'Designed medical oriented interfaces integrated into big medical systems.'},
                    {type: 'li', className: 'ListElement', text: 'Created data analysis software.'},
                {type: 'li', className: 'ListElement', text: 'Emulated and tested medical devices with research software.'},
                {type: 'li', className: 'ListElement', text: 'Worked closely with a team of medical researchers.'}
            ]},

            {type: 'div', className: 'PlaceLocationDate', elements: [
                {type: 'a', className: 'Place', text: 'Arizona State University', href: '#Portfolio'},
                {type: 'div', className: 'Location', text: ' - Junction City, Kansas'},
                {type: 'div', className: 'Date', text: '2012 to 2014'}
            ]},
            {type: 'div', className: 'Position', text: 'Mobile Software Developer'},
            {type: 'div', className: 'PositionDescription', text: 'Developed software for Arizona State University.'},
            {type: 'ul', className: 'WorkTasks', elements: [
                {type: 'li', className: 'ListElement', text: 'Created user friendly interfaces.'},
                {type: 'li', className: 'ListElement', text: 'Designed iOS and Android software from start until release.'},
                {type: 'li', className: 'ListElement', text: 'Followed deadlines and budget restrictions.'}
            ]},

            {type: 'div', className: 'PlaceLocationDate', elements: [
                {type: 'a', className: 'Place', text: 'Lead Horse Technologies', href: '#Portfolio'},
                {type: 'div', className: 'Location', text: ' - Junction City, Kansas'},
                {type: 'div', className: 'Date', text: '2010 to 2012'}
            ]},
            {type: 'div', className: 'Position', text: 'Software Developer Intern'},
            {type: 'div', className: 'PositionDescription', text: 'Developer medical software including iOS applications, interfaces and backend connections.'},
            {type: 'ul', className: 'WorkTasks', elements: [
                {type: 'li', className: 'ListElement', text: 'Collaborated with a team to produce medical safety software.'},
                {type: 'li', className: 'ListElement', text: 'Designed user intuitive interfaces meant for patients and nurses.'},
                {type: 'li', className: 'ListElement', text: 'Used real time data to diagnose patient symptoms from a database system.'}
            ]},

            {type: 'div', className: 'MainHeader', text: 'Education'},
            {type: 'hr', className: 'MainHR'},
            {type: 'div', className: 'PlaceLocationDate', elements: [
                {type: 'div', className: 'Place', text: 'KANSAS STATE UNIVERSITY'},
                {type: 'div', className: 'Location', text: ' - Manhattan, Kansas'},
                {type: 'div', className: 'Date', text: 'May 2017'}
            ]},
            {type: 'div', className: 'Degree', text: 'Bachelors of Computer Science'},

                {type: 'div', className: 'MainHeader', text: 'Certifications and Licenses'},
                {type: 'hr', className: 'MainHR'},
                {type: 'div', className: 'CertificationAndDate', elements: [
                        {type: 'div', className: 'Certification', text: 'IBM Informix on Cloud - Server Fundamentals'},
                        {type: 'div', className: 'Date', text: 'November 2017 to Present'}
                    ]},
                {type: 'div', className: 'CertificationText', text: 'This badge earner has demonstrated knowledge of Informix terminology, data types, workloads, and\n' +
                        'environment variables and configuration.\n' +
                        'https://www.youracclaim.com/badges/d2fcf7cf-618b-416b-980c-a1358c37d9cc'},

                {type: 'div', className: 'CertificationAndDate', elements: [
                        {type: 'div', className: 'Certification', text: 'Cypress Modern Automation Testing'},
                        {type: 'div', className: 'Date', text: 'April 2021 to Present'}
                    ]},
                {type: 'div', className: 'CertificationText', text: 'Certification of completion for Cypress Modern Automation Testing.\n' +
                        'https://ude.my/UC-deefe3b3-2e6f-4653-9f36-b14d48ce70f9'},

                {type: 'div', className: 'CertificationAndDate', elements: [
                        {type: 'div', className: 'Certification', text: 'Terraform Infrastructure as Code'},
                        {type: 'div', className: 'Date', text: 'April 2022 to Present'}
                    ]},
                {type: 'div', className: 'CertificationText', text: 'Certificate of completion for Terraform Infrastructure as Code.\n' +
                        'https://ude.my/UC-f9aa57ff-5120-4250-b236-a07b569a301f'},

                {type: 'div', className: 'CertificationAndDate', elements: [
                        {type: 'div', className: 'Certification', text: 'AWS Proficiency Course'},
                        {type: 'div', className: 'Date', text: '2020 to Present'}
                    ]},
                {type: 'div', className: 'CertificationText', text: 'Completed an in-person multi-week AWS deep dive zero to proficient course.'},

                {type: 'div', className: 'CertificationAndDate', elements: [
                        {type: 'div', className: 'Certification', text: 'GraphQL Course'},
                        {type: 'div', className: 'Date', text: '2020 to Present'}
                    ]},
                {type: 'div', className: 'CertificationText', text: 'Completed a course to learn the creation and usage of GraphQL apis.'},

                {type: 'div', className: 'MainHeader', text: 'Publications'},
                {type: 'hr', className: 'MainHR'},

                {type: 'div', className: 'PublicationAndDate', elements: [
                        {type: 'div', className: 'Publication', text: 'eyeDNS: Monitoring a University Campus Network'},
                        {type: 'div', className: 'Date', text: 'May 2018'}
                    ]},
                {type: 'div', className: 'PublicationText', text: '“eyeDNS: Monitoring a University Campus Network”. In IEEE Inter-national Conference on\n' +
                        'Communications (ICC) – Communications and Information System SecuritySymposium (CISE), Kansas\n' +
                        'City, MO, USA, May, 2018.\n' + 'http://mlsec.info/publication/icc18/'},


                {type: 'div', className: 'MainHeader', text: 'Additional Information'},
                {type: 'hr', className: 'MainHR'},

                {type: 'ul', className: '', elements: [
                        {type: 'li', className: 'ListElement', text: 'Very familiar and practiced with DevOps work, setting up infrastructure, designated first responder, and on-call problem solving.'},
                        {type: 'li', className: 'ListElement', text: 'Full stack developer experience from design, database, model, networking, server setup, testing, deployment, backend logic to UI and client side logic. Including client deployment on custom hardware.'},
                        {type: 'li', className: 'ListElement', text: 'Complete product development according to budget, timeline, and deliverables.'},
                        {type: 'li', className: 'ListElement', text: 'Experience with real-time, low-latency, high throughput, high user activity, scalable, live and interactive systems.'},
                        {type: 'li', className: 'ListElement', text: 'Led many design reviews on multiple products and technologies with peers, product managers, stakeholders, and upper management.'},
                        {type: 'li', className: 'ListElement', text: 'High throughput in resolving tickets, following up on analysis, creating follow up issues, code reviews, documenting, managing releases and deployments, QAing, and monitoring.'},
                        {type: 'li', className: 'ListElement', text: 'Frequent experience with backlog grooming, triaging, agile, scrum, kanban, and ticketing management systems like Jira.'},
                    ]},
        ]});
    }
    showPage() {
        WebHistory.PushStateChange(['Resume']);
    }
    hidePage() {

    }
    getInterface() {
        return this.mainInterface;
    }
}
