import './ProjectsPage.scss';
import {IGenericPage} from "../../Utility/Components/IGenericPage";
import {Interface} from "../../Utility/Interface";
import {WebHistory} from "../../Utility/WebHistory";
// Todo: Can this be an import instead?
const reversiVideo = require('../../../static/assets/Reversi.mp4');
const artGameVideo = require('../../../static/assets/ArtGame.mp4');
const forumVideo = require('../../../static/assets/Forum.mp4');

export class ProjectsPage implements IGenericPage {
    mainInterface: HTMLElement;
    artGameVideoElement: HTMLVideoElement;
    forumVideoElement: HTMLVideoElement;
    readyToPlayArtGameVideo: boolean = false;
    reversiVideoElement: HTMLVideoElement;
    readyToPlayReversiVideo: boolean = false;
    readyToPlayForumVideo: boolean = false;

    constructor() {
        this.mainInterface = Interface.Create({type: 'div', className: 'ProjectsPage', elements: [
                {type: 'div', className: 'GithubDiv', elements: [
                        {type: 'div', className: 'GithubText', text: 'Check out my Github at'},
                        {type: 'a', className: 'GithubLink', href: 'https://github.com/MatthewFrench?tab=repositories', text: 'https://github.com/MatthewFrench', target: '_blank'}
                    ]},
                {type: 'div', className: 'TryOutDiv', elements: [
                        {type: 'div', className: '', text: 'Try out these projects:'}
                    ]}
            ]});

        this.addProjects();
    }

    addProjects() {
        let posts = [];
        this.artGameVideoElement = Interface.Create({type: 'video', className: 'ArtGameImg', width: 1637, height: 966, attributes: {autoplay: '', loop: '', muted: '', preload: 'metadata', "webkit-playsinline": '', playsinline: ''}, elements: [
                {type:'source', src: artGameVideo}
            ]});
        // This is a fix to allow videos to autoplay in Chrome
        this.artGameVideoElement.oncanplaythrough = () => {
            this.artGameVideoElement.muted = true;
            this.artGameVideoElement.play();
            this.readyToPlayArtGameVideo = true;
        }
        posts.push({type: 'a', href: 'https://art.matthewfrench.io/', target: '_blank', elements: [
                {type: 'div', className: 'ProjectsPost ArtGamePost', elements: [
                        {type: 'div', className: 'Header ArtGameHeader', elements: [
                                {type: 'div', className: 'Title', text: 'Art Platformer Online'},
                            ]},
                        {type: 'div', className: 'ArtGameContainer', elements: [
                                {type: 'div', className: 'ArtGamePlayDiv', text: "Click to Play the Online Art Game"},
                                this.artGameVideoElement,
                                {type: 'div', className: 'ArtGameText', text: "This game was an experiment in having an online platformer world be created by the players as art."}
                            ]},
                    ]}
            ]}
        );
        this.forumVideoElement = Interface.Create({type: 'video', className: 'ForumImg', width: 2432, height: 1500, attributes: {autoplay: '', loop: '', muted: '', preload: 'metadata', "webkit-playsinline": '', playsinline: ''}, elements: [
                {type:'source', src: forumVideo}
            ]});
        // This is a fix to allow videos to autoplay in Chrome
        this.forumVideoElement.oncanplaythrough = () => {
            this.forumVideoElement.muted = true;
            this.forumVideoElement.play();
            this.readyToPlayForumVideo = true;
        }
        posts.push(
            {type: 'a', href: 'https://forum.matthewfrench.io/', target: '_blank', elements: [
                    {type: 'div', className: 'ProjectsPost ForumPost', elements: [
                            {type: 'div', className: 'Header ForumHeader', elements: [
                                    {type: 'div', className: 'Title', text: 'Forum'},
                                ]},
                            {type: 'div', className: 'ForumContainer', elements: [
                                    {type: 'div', className: 'ForumPlayDiv', text: "Click to check out my forum!"},
                                    this.forumVideoElement,
                                    {type: 'div', className: 'ForumText', text: "This was an experiment to make a real time forum. You could see who was online, who was there, who was typing. All elements of the forum were alive and live-updated, no refreshing or reloading. A live chat forum that feels like you are directly talking to others in the same room."}
                                ]},
                        ]}
                ]}
        );
        this.reversiVideoElement = Interface.Create({type: 'video', className: 'ReversiImg', width: 1148, height: 1284, attributes: {autoplay: '', loop: '', muted: '', preload: 'metadata', "webkit-playsinline": '', playsinline: ''}, elements: [
                {type:'source', src: reversiVideo}
            ]});
        this.reversiVideoElement.oncanplaythrough = () => {
            this.reversiVideoElement.muted = true;
            this.reversiVideoElement.play();
            this.readyToPlayReversiVideo = true;
        }
        posts.push({type: 'a', href: 'https://matthewfrench.github.io/Reversi/', target: '_blank', elements: [
            {type: 'div', className: 'ProjectsPost ReversiPost', elements: [
                {type: 'div', className: 'Header ReversiHeader', elements: [
                        {type: 'div', className: 'Title', text: 'Reversi'},
                            ]},
                    {type: 'div', className: 'ReversiContainer', elements: [
                            {type: 'div', className: 'ReversiPlayDiv', text: "Click to Play Reversi"},
                            {type: 'div', elements: [
                                    this.reversiVideoElement,
                                    {type: 'div'}
                                ]},
                            {type: 'div', className: 'ArtGameText', text: "Play against other players, play against a bot, or watch two bots fight each other."}
                        ]},
                    ]}
            ]});
        for (let post of posts) {
            if (Object.hasOwn(post, 'description') && Object.hasOwn(post, 'title')) {
                this.addProjectsPost(post);
            } else {
                this.mainInterface.appendChild(Interface.Create(post));
            }
        }
    }

    addProjectsPost({title, description, date} : {title: string, description: any, date: Date}) {
        let projectsPost = Interface.Create({type: 'div', className: 'ProjectsPost', elements: [
                {type: 'div', className: 'Header', elements: [
                        {type: 'div', className: 'Title', text: title},
                        // Hiding date for now unless I have a reason to show the date
                        //{type: 'div', className: 'Date', text: date.toLocaleString()}
                    ]}
            ]});
        if (typeof description === 'string') {
            projectsPost.appendChild(Interface.Create({type: 'div', className: 'Description', text: description}));
        } else {
            projectsPost.appendChild(Interface.Create({type: 'div', className: 'Description', elements: [description]}));
        }
        this.mainInterface.appendChild(projectsPost);
    }

    playVideos() {
        // This re-plays videos when the user returns to the page
        if (this.readyToPlayArtGameVideo) {
            this.artGameVideoElement.muted = true;
            this.artGameVideoElement.play();
        }
        if (this.readyToPlayReversiVideo) {
            this.reversiVideoElement.muted = true;
            this.reversiVideoElement.play();
        }
        if (this.readyToPlayForumVideo) {
            this.forumVideoElement.muted = true;
            this.forumVideoElement.play();
        }
    }

    showPage() {
        WebHistory.PushStateChange(['Projects']);
        this.playVideos();
    }

    hidePage() {

    }

    getInterface() {
        return this.mainInterface;
    }
}