import './PortfolioPage.scss';
import {IGenericPage} from "../../Utility/Components/IGenericPage";
import {Interface} from "../../Utility/Interface";
import {WebHistory} from "../../Utility/WebHistory";
import {PortfolioSection} from "./PortfolioSection";
import {PortfolioProject} from "./PortfolioProject";

export class PortfolioPage implements IGenericPage {
    mainInterface: HTMLElement;
    ncsProjects: PortfolioSection;
    cybersecurityProjects: PortfolioSection;
    researchGroupProjects: PortfolioSection;
    arizonaStateUniversityProjects: PortfolioSection;
    leadHorseTechProjects: PortfolioSection;
    hobbyProjects: PortfolioSection;

    constructor() {
        this.mainInterface = Interface.Create({type: 'div', className: 'PortfolioPage'});
        this.addSections();
        this.addProjects();
    }

    addSections() {
        this.ncsProjects = new PortfolioSection('Network Computer Solutions', '2017 to Now');
        this.cybersecurityProjects = new PortfolioSection('Cybersecurity K-State', '2016 to 2017');
        this.researchGroupProjects = new PortfolioSection('K-State Research Group', '2014 to 2016');
        this.arizonaStateUniversityProjects = new PortfolioSection('Arizona State University', '2012 to 2013');
        this.leadHorseTechProjects = new PortfolioSection('Lead Horse Technologies', '2010 to 2012');
        this.hobbyProjects = new PortfolioSection('Hobby Projects', '2006 to Now');
        this.mainInterface.appendChild(this.ncsProjects.getInterface());
        this.mainInterface.appendChild(this.cybersecurityProjects.getInterface());
        this.mainInterface.appendChild(this.researchGroupProjects.getInterface());
        this.mainInterface.appendChild(this.arizonaStateUniversityProjects.getInterface());
        this.mainInterface.appendChild(this.leadHorseTechProjects.getInterface());
        this.mainInterface.appendChild(this.hobbyProjects.getInterface());
    }

    addProjects() {
        this.ncsProjects.addProject(new PortfolioProject('User Access Control System',
                '2018',
                'PHP/CSS/HTML/Javascript/Typescript/Webpack/MySQL',
                'Added a user access control system to an existing large time management product. Groups are given permissions and users are assigned to groups. Created both backend implementation with existing infrastructure and the UI for manipulating user permissions.'));
        this.ncsProjects.addProject(new PortfolioProject('Server Keep-Alive Tool',
            '2018', 'NodeJS/Javascript/Electron/PHP/MySQL', 'Keeps the book keeping server software running by watching for crashes, errors and freezes. Restarting the software within 5 minutes of an issue.'));
        this.ncsProjects.addProject(new PortfolioProject('Timeclock App',
            '2017', 'JS/HTML/Electron/Typescript/Webpack/C#/PHP/MySQL', 'The timeclock app is currently being used in different hospitals, clinics, and care centers in the area. It is also being used for a few non-medical businesses. The timeclock app itself is a javascript application bundled as an Electron desktop app. It works on Mac, Windows and Linux. It currently runs on touch screen Raspberry PI kiosks.'));
        this.ncsProjects.addProject(new PortfolioProject('Employee Portal',
            '2017', 'HTML/Javascript/CSS/Typescript/Webpack/PHP/MySQL', 'An automatic updating document portal for employees. Allows creation of documents, modifying documents and viewing documents. Documents may be nested in folders. Documents auto-update with changes to all currently viewing. Documents stored as deltas, allowing the document to be reverted to any past version.'));
        this.ncsProjects.addProject(new PortfolioProject('Payment Portal Site',
            '2017', 'HTML/Javascript/CSS/Typescript/Webpack/C#/PHP/MySQL', 'Customer account management website allowing viewing delivery dates, requesting deliveries, updating account information, viewing invoices and making payments. Entire site is a self-updating single web application.'));
        this.ncsProjects.addProject(new PortfolioProject('Geofence Tool',
            '2017', 'HTML/Javascript/CSS/PHP/MySQL/Maps API', 'Created a management tool that allowed technicians to map fences of businesses interactively by searching for the location and dragging visible borders on a map. This allowed the technician tracking software to accurately place technicians at the businesses they are servicing.'));
        this.ncsProjects.addProject(new PortfolioProject('Part Checkout Tool',
            '2017', 'HTML/Javascript/CSS/PHP/MySQL', 'Wrote a tool to be used on tablet machines(windows tablet/iPad) that allows technicians to scan in a part, give a description(and other pertinent part information) and check it in. Replaces the existing handwritten part list and automates the check-in that previously the secretaries would have to manually input'));


        this.cybersecurityProjects.addProject(new PortfolioProject('EyeDNS',
            '2016',
            'JS/HTML/CSS/Python/MongoDB',
            'EyeDNS was a real time network analysis tool. It helped create a research paper on traffic going through K-State. The purpose was to find malicious and suspicious activity through terabytes of data and real time data being processed. The app was a single page web app that could search through the database with the supplied parameters and showed graphs and trends.'));


        this.researchGroupProjects.addProject(new PortfolioProject('Adventure Game Engine',
            'Todo', 'Todo', 'Todo'));
        this.researchGroupProjects.addProject(new PortfolioProject('MDCF Interface',
            'Todo', 'JS/HTML/CSS/Dart/Java', 'Todo'));
        this.researchGroupProjects.addProject(new PortfolioProject('Phishing Game',
            'Todo', 'Todo', 'Todo'));


        this.arizonaStateUniversityProjects.addProject(new PortfolioProject('Spanish Math Learning Game',
            'Todo', 'Flash/Obj-C/iOS', 'Todo'));
        this.arizonaStateUniversityProjects.addProject(new PortfolioProject('IQ Tester App',
            'Todo', 'Obj-C/Java/XML/iOS/Android', 'Todo'));


        this.leadHorseTechProjects.addProject(new PortfolioProject('Medloom',
            '2010 to 2012',
            'Obj-C/iOS',
            'I worked for a medical company to produce Medloom, an iPhone and iPad app that given symptoms, personal details and medication- it could highlight machine learned trends using FDA analysis to give the most likely reasons for potential problems. An example was that it could warn about Myocardial Infarction(heart attack) when certain medications are listed by the user. It\'s purpose was to assist doctors.'));

        this.hobbyProjects.addProject(new PortfolioProject('Art Platformer Online',
            '2017',
            'JS/HTML/CSS/MySQL/NodeJS/Typescript',
            'I worked with a team to make a real time online game in the web browser. The purpose was to have players create worlds that other players could join to view artwork and create artwork in a collaborative platformer environment.'));

        this.hobbyProjects.addProject(new PortfolioProject('Oberin Open Source',
            'Todo', 'C', 'Todo'));
        this.hobbyProjects.addProject(new PortfolioProject('Auto Key Presser',
            'Todo', 'Todo', 'Todo'));
        this.hobbyProjects.addProject(new PortfolioProject('Balloon Pop Game',
            'Todo', 'Todo', 'Todo'));
        this.hobbyProjects.addProject(new PortfolioProject('Cannon Ball Game',
            'Todo', 'C++/SFML', 'Todo'));
        this.hobbyProjects.addProject(new PortfolioProject('Door Auto Unlocker',
            'Todo', 'Todo', 'Todo'));
        this.hobbyProjects.addProject(new PortfolioProject('HTML5 GameMaker Online',
            'Todo', 'Todo', 'Todo'));
        this.hobbyProjects.addProject(new PortfolioProject('HVAC Designer',
            'Todo', 'Todo', 'Todo'));
        this.hobbyProjects.addProject(new PortfolioProject('iRPG',
            'Todo', 'Todo', 'Todo'));
        this.hobbyProjects.addProject(new PortfolioProject('iSpinner',
            'Todo', 'Todo', 'Todo'));
        this.hobbyProjects.addProject(new PortfolioProject('League Autoplay',
            'Todo', 'C++', 'Todo'));
        this.hobbyProjects.addProject(new PortfolioProject('League Developer Client',
            'Todo', 'Todo', 'Todo'));
        this.hobbyProjects.addProject(new PortfolioProject('Isometric Map Editor',
            'Todo', 'Todo', 'Todo'));
        this.hobbyProjects.addProject(new PortfolioProject('Scripting Engine',
            'Todo', 'Todo', 'Todo'));
        this.hobbyProjects.addProject(new PortfolioProject('Physics Ball',
            'Todo', 'Todo', 'Todo'));
        this.hobbyProjects.addProject(new PortfolioProject('Quest of Magic',
            'Todo', 'Todo', 'Todo'));
        this.hobbyProjects.addProject(new PortfolioProject('Reversi',
            'Todo', 'Todo', 'Todo'));
        this.hobbyProjects.addProject(new PortfolioProject('SpaceCraft',
            'Todo', 'Todo', 'Todo'));
        this.hobbyProjects.addProject(new PortfolioProject('Teach Tools',
            'Todo', 'Todo', 'Todo'));
    }

    showPage() {
        WebHistory.PushStateChange(['Portfolio']);
    }

    hidePage() {

    }

    getInterface() {
        return this.mainInterface;
    }
}